/**
 * Unregisters all currently registered service workers and returns a boolean that indicates
 * whether there were service workers registered or not.
 */
export const unregisterServiceWorkers = async (): Promise<boolean> => {
  if (!navigator.serviceWorker) {
    return false;
  }

  const registrations = await navigator.serviceWorker.getRegistrations();

  const unregistrations = registrations.map(registration => registration.unregister().catch());

  // Await all unregistrations
  await Promise.all(unregistrations);

  return registrations.length > 0;
};
