import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ThematicsEffects } from './+state/thematics.effects';
import { THEMATICS_FEATURE_STATE } from './+state/thematics.reducer';

// NOTES : ThematicsModule is not lazy loaded because we need to dispatch actions from thematic state in cause state
// If you need to lazy load this module for performance reason, you probably can :
// 1. merge module causes and thematics in feature module lazy loaded
// 2. put thematics state in app State and remove feature state for thematics

@NgModule({
  imports: [
    StoreModule.forFeature(THEMATICS_FEATURE_STATE),
    EffectsModule.forFeature([ThematicsEffects])
  ]
})
export class ThematicsModule {}
