/* eslint-disable */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, NEVER, of } from 'rxjs';
import { settingsActions as actions } from './settings.actions';
import { SettingsService } from './settings.service';

@Injectable()
export class SettingsEffects {
  public loadFeatureSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadFeatureSettings),
      concatMap(() => {
        return this.settingsService.loadFeatureSetttings().pipe(
          map(features => actions.loadFeatureSettingsSuccess({ payload: features })),
          catchError((error: any) => of(actions.loadFeatureSettingsFailure({ error })))
        );
      })
    );
  });

  public loadFeatureSettingsError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.loadFeatureSettingsFailure),
        concatMap(
          () =>
            // TODO error behaviour ?
            NEVER
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly settingsService: SettingsService
  ) {}
}
