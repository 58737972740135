import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { Injector } from '@angular/core';
import { AVAILABLE_LANG, DEFAULT_LANG } from '@configs/app.config';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

/**
 * waits until translations are loaded
 * see https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
 */
export const initializerFactory =
  (injector: Injector, translate: TranslateService) => (): Promise<null> =>
    new Promise<null>((resolve: (_: null) => void) => {
      registerLocaleData(localeFr);
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

      locationInitialized.then(() => {
        translate.addLangs(AVAILABLE_LANG);
        translate.setDefaultLang(DEFAULT_LANG);
        const lang = translate.getBrowserLang();

        translate
          .use(translate.getLangs().includes(lang) ? lang : translate.defaultLang)
          .pipe(take(1))
          .subscribe({
            complete: () => {
              resolve(null);
            }
          });
      });
    });
