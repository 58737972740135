import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FeatureSettings } from './settings.state';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private readonly SETTINGS_URI = `${environment.adventureUri}/feature-settings`;

  constructor(private http: HttpClient) {}

  public loadFeatureSetttings(): Observable<FeatureSettings> {
    return this.http.get<FeatureSettings>(`${this.SETTINGS_URI}`);
  }
}
