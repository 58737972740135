import { ApplicationName } from '@models/context/application-name';

/**
 * A config setting the specific href of the favicon
 *
 * @param applicationName The application name
 * @returns The specific href of the favicon, default if the application has the default favicon
 */
export const FAVICON_CONFIG = (applicationName: string): string => {
  switch (applicationName) {
    case ApplicationName.PaysDeLaLoire:
      return 'assets/img/favicon-pays-de-la-loire.svg';
    case ApplicationName.RegionSud:
      return 'assets/img/favicon-region-sud.svg';
    default:
      return null;
  }
};
