import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { settingsReducer } from './settings/settings.reducer';
import { SettingsState } from './settings/settings.state';

export interface AppState {
  router: RouterReducerState;
  settings: SettingsState;
}

export const appReducer = {
  // Notes: add NOT lazy loaded state here or shared state (like sharedState, authState...)
  router: routerReducer,
  settings: settingsReducer
};
