import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@core/modules/core.module';
import { ThematicsModule } from '@features/thematics/thematics.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { MetaReducer, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConsentBannerModule } from '@organisms/consent-banner/consent-banner.module';
import { MaterialModule } from '@shared/modules/material.module';
import { CustomSerializer } from 'app/store/router/custom-route-serializer';
import { environment } from 'environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { appReducer } from './store/app.state';
import { settingsActions } from './store/settings/settings.actions';
import { SettingsEffects } from './store/settings/settings.effects';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze] : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRouting,
    BrowserAnimationsModule,
    CommonModule,
    ConsentBannerModule,
    CoreModule,
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    StoreModule.forRoot(appReducer, { metaReducers }),
    EffectsModule.forRoot([SettingsEffects]),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
      autoPause: true
    }),
    ThematicsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private store: Store) {
    this.store.dispatch(settingsActions.loadFeatureSettings());
  }
}
