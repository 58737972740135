import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { APP_VERSION } from '@configs/app.config';
import { createErrorHandler, init, routingInstrumentation, TraceService } from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { environment } from 'environments/environment';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    }
  ]
})
export class SentryModule {
  // NOTE: See https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
  constructor(@Optional() @SkipSelf() parentModule: SentryModule) {
    if (parentModule) {
      throw new Error(
        `${parentModule.constructor.name} has already been loaded. Import this module in the AppModule only.`
      );
    }

    // Initialise Sentry
    // Notes: If Sentry doesn't work, put this section in initApp (app-pre-boot-initializer)
    if (environment.analyticsEnvironment !== 'local') {
      init({
        dsn: 'https://37bd4fa1b523444598b22ab04e266f12@app.glitchtip.com/2175',
        environment: environment.analyticsEnvironment,
        integrations: [
          new BrowserTracing({
            routingInstrumentation
          })
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        // NOTE : Reduced for the moment as we are testing Sentry as a trial
        tracesSampleRate: 0.1,
        release: `gps@${APP_VERSION}`,
        autoSessionTracking: false
      });
    }
  }
}
