import { OAuthModuleConfig } from 'angular-oauth2-oidc';

/**
 * Inject the Authentication Token in HTTP requests to /apis
 */
export const oAuthModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: ['/apis'],
    sendAccessToken: true
  }
};
