import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FeatureSettings } from './settings.state';

export const settingsActions = createActionGroup({
  source: 'Settings',
  events: {
    'Load Feature Settings': emptyProps(),
    'Load Feature Settings Success': props<{ payload: FeatureSettings }>(),
    'Load Feature Settings Failure': props<{ error: Error }>()
  }
});
