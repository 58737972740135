import { createReducer, on } from '@ngrx/store';
import { settingsActions } from './settings.actions';
import { initialSettingsState, SettingsState } from './settings.state';

export const settingsReducer = createReducer(
  initialSettingsState,
  on(
    settingsActions.loadFeatureSettings,
    (state): SettingsState => ({
      ...state,
      features: {
        ...state.features,
        loading: true
      }
    })
  ),
  on(
    settingsActions.loadFeatureSettingsSuccess,
    (state, action): SettingsState => ({
      ...state,
      features: {
        ...state.features,
        ...action.payload,
        loading: false
      }
    })
  ),
  on(
    settingsActions.loadFeatureSettingsFailure,
    (state, action): SettingsState => ({
      ...state,
      features: {
        ...state.features,
        error: action.error,
        loading: false
      }
    })
  )
);
