import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingService } from '@core/services/';
import { finalize, Observable } from 'rxjs';

// NOTE: interceptor is used to update loading$ in LoadingService which is used for @loading decorator.

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  public count = 0;

  constructor(private loading: LoadingService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.count === 0) {
      this.loading.show();
    }

    this.count++;

    return next.handle(request).pipe(
      finalize(() => {
        this.count--;

        if (this.count === 0) {
          this.loading.hide();
        }
      })
    );
  }
}
