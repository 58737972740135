import { Injectable, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Subject, takeUntil } from 'rxjs';

@Injectable()
export class DayjsService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  public init(): void {
    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: LangChangeEvent) => {
        dayjs.locale(event.lang);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
