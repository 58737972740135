import { ApplicationContext, AuthService, SituationContext } from '@core/services';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export const initApp =
  (
    context: ApplicationContext,
    authService: AuthService,
    situationContext: SituationContext
  ): (() => Observable<void>) =>
  (): Observable<void> =>
    // AuthService is initialized first so when the context loads the app, the token in the local storage is correct
    authService.initAuth().pipe(
      // Context is initialized then so other services are not initialized if not needed (dispatch, unknown app, ...)
      switchMap(() => context.init()),
      tap(() => situationContext.init())
    );
