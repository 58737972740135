import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationContext } from '@core/services';
import { Observable } from 'rxjs';

@Injectable()
export class ContextInterceptor implements HttpInterceptor {
  constructor(private context: ApplicationContext) {}

  /**
   * Intercept requests and add X-TENANT-ID header to requests to APIs with the application name and X-PRODUCT to the product name
   *
   * @param req The request
   * @param next The HTTP handler
   * @returns An observable executing the request
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { applicationName, product } = this.context;

    if (req.url.startsWith('/apis/') && applicationName && product) {
      return next.handle(
        req.clone({
          setHeaders: { 'X-TENANT-ID': applicationName, 'X-PRODUCT': product }
        })
      );
    }

    return next.handle(req);
  }
}
