import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EXTERNAL_LINKS_CONFIG } from '@configs/external-links.config';
import { RouteIds } from '@core/routing/route-ids';
import { AnalyticsService, ApplicationContext } from '@core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-consent-banner',
  templateUrl: './consent-banner.component.html',
  styleUrls: ['./consent-banner.component.scss']
})
export class ConsentBannerComponent implements OnInit, OnDestroy {
  public externalLinks: boolean;

  public get isAdminPage(): boolean {
    return this.route.snapshot.data.routeId === RouteIds.ADMIN;
  }

  private onDestroySubject$ = new Subject<void>();

  constructor(
    private analyticsService: AnalyticsService,
    private context: ApplicationContext,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.externalLinks = EXTERNAL_LINKS_CONFIG(this.context.product);
  }

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }

  public onConsent(): void {
    this.analyticsService.consent().pipe(takeUntil(this.onDestroySubject$)).subscribe();
  }

  public onDismiss(): void {
    this.analyticsService.dismiss();
  }
}
