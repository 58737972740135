/**
 * It seems that for now, angular-oauth2-oidc module does not handle properly expired access token
 * This interceptor is a temporary fix
 * The issue to follow: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/820
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class UnsafeTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private oauth: OAuthService) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.oauth.hasValidAccessToken) {
      this.auth.emptyAuthStorage();
      this.auth.logout(true);
    }

    return next.handle(req);
  }
}
