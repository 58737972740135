// NOTES : essai à généraliser si ok
interface RemoteData {
  loading: boolean;
  error: Error | null;
}

export interface FeatureSettings {
  expeditions: boolean;
  authUseSso: boolean;
  dataTab: boolean;
  itinerariesTab: boolean;
  testimonyIntention: boolean;
  testimonyUrl: string;
  companyIntention: boolean;
  jobTeaserPrefix: string;
  diplomaTypes: boolean;
  bridges: boolean;
}

export type FeatureTypes =
  | 'expeditions'
  | 'authUseSso'
  | 'dataTab'
  | 'itinerariesTab'
  | 'testimonyIntention'
  | 'testimonyUrl'
  | 'companyIntention'
  | 'jobTeaserPrefix'
  | 'diplomaTypes'
  | 'bridges';

export interface FeatureSettingsState extends RemoteData, FeatureSettings {}

export interface SettingsState {
  features: FeatureSettingsState;
  // NOTES: ajouter à l'avenir tout ce qui touche aux paramètres de l'application
  //   application: string;
  //   product: string;
}

export const initialSettingsState = {
  features: {
    expeditions: false,
    authUseSso: false,
    dataTab: false,
    itinerariesTab: false,
    testimonyIntention: false,
    testimonyUrl: null,
    companyIntention: false,
    jobTeaserPrefix: null,
    diplomaTypes: false,
    bridges: false,
    loading: false,
    error: null
  }
};
