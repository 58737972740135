import { createFeature, createReducer, on } from '@ngrx/store';
import { thematicsActions } from './thematics.actions';
import {
  initialThematicsState,
  thematicsAdapter,
  ThematicsState,
  THEMATICS_FEATURE_KEY
} from './thematics.state';

export const thematicsReducer = createReducer(
  initialThematicsState,
  on(
    thematicsActions.loadSingleThematic,
    (state): ThematicsState => ({
      ...state,
      loading: true
    })
  ),
  on(thematicsActions.loadSingleThematicSuccess, (state, action) =>
    thematicsAdapter.addOne(action.thematic, { ...state, loading: false })
  ),
  on(
    thematicsActions.loadSingleThematicFailure,
    (state, action): ThematicsState => ({
      ...state,
      loading: false,
      error: action.error
    })
  ),
  on(
    thematicsActions.loadThematics,
    (state): ThematicsState => ({
      ...state,
      loading: true
    })
  ),
  on(thematicsActions.loadThematicsSuccess, (state, action) =>
    thematicsAdapter.addMany(action.thematics, { ...state, loading: false })
  ),
  on(
    thematicsActions.loadThematicsFailure,
    (state, action): ThematicsState => ({
      ...state,
      loading: false,
      error: action.error
    })
  )
);

export const THEMATICS_FEATURE_STATE = createFeature({
  name: THEMATICS_FEATURE_KEY,
  reducer: thematicsReducer
});
