/* eslint-disable */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import { ErrorCodes } from '@models/error-codes/error-codes';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, withLatestFrom } from 'rxjs';
import { ThematicsService } from '../services/thematiques.service';
import { thematicsActions } from './thematics.actions';
import { selectThematicEntities, selectThematics } from './thematics.selectors';
@Injectable()
export class ThematicsEffects {
  public loadThematic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(thematicsActions.loadSingleThematic),
      withLatestFrom(this.store.select(selectThematicEntities)),
      concatMap(([action, entities]) => {
        const thematic = entities[action.uuid];

        if (thematic) {
          return of(thematicsActions.loadSingleThematicSuccess({ thematic }));
        }

        return this.thematicsService.getThematicAll(action.uuid).pipe(
          map(thematic => thematicsActions.loadSingleThematicSuccess({ thematic })),
          catchError((error: any) => of(thematicsActions.loadSingleThematicFailure({ error })))
        );
      })
    );
  });

  public loadThematicError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(thematicsActions.loadSingleThematicFailure),
        concatMap(() =>
          // TODO go to the route corresponding to the error when the error pages are made (by default we route in 404 page)
          of(this.router.navigateByUrl(`/${AppRouteUrls.ERROR(ErrorCodes.ERROR_404)}`))
        )
      );
    },
    { dispatch: false }
  );

  public loadThematics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(thematicsActions.loadThematics),
      withLatestFrom(this.store.select(selectThematics)),
      concatMap(([_, thematics]) => {
        const allThematicsCount = 17;

        if (thematics.length >= allThematicsCount) {
          return of(thematicsActions.loadThematicsSuccess({ thematics }));
        }

        return this.thematicsService.getThematicsCauses().pipe(
          map(thematics => thematicsActions.loadThematicsSuccess({ thematics })),
          catchError((error: any) => of(thematicsActions.loadThematicsFailure({ error })))
        );
      })
    );
  });

  public loadThematicsError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(thematicsActions.loadThematicsFailure)
        // TODO concatMap((action) =>  show message action.error)
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly thematicsService: ThematicsService,
    private readonly store: Store,
    private readonly router: Router
  ) {}
}
