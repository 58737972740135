import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { unregisterServiceWorkers } from 'app/unregister-service-worker';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

// Unregister all installed service workers and force reload the page if there was
// an old service worker from a previous version.
// eslint-disable-next-line no-restricted-globals
unregisterServiceWorkers().then(hadServiceWorker => hadServiceWorker && location.reload());

if (environment.production) enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    if (!environment.production) console.error(err);
  });
