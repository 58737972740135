<p class="consent-text">
  {{
    (isAdminPage ? "consent-banner.consent-text-admin" : "consent-banner.consent-text") | translate
  }}
  <a
    *ngIf="externalLinks; else noExternalLink"
    class="privacy-policy-link"
    [href]="'accept-policy.privacy-policy-link' | translate"
    target="_blank"
    translate
  >
    consent-banner.privacy-policy</a
  >.

  <ng-template #noExternalLink>
    {{ "consent-banner.privacy-policy" | translate }}
  </ng-template>
</p>

<div class="btn-container">
  <button mat-button class="dismiss-btn" (click)="onDismiss()">
    {{ "consent-banner.refuse" | translate }}
  </button>

  <button mat-flat-button color="white" (click)="onConsent()">
    {{ "consent-banner.acknowledge" | translate }}
  </button>
</div>
