import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncodeParamCodec } from './encode-params-codec';

@Injectable()
export class EncodeParamsInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If any params contains a +, params are encoded safely
    if (req.params.keys().some(key => req.params.get(key).includes('+'))) {
      const encodedParams = new HttpParams({
        fromString: req.params.toString(),
        encoder: new EncodeParamCodec()
      });

      return next.handle(
        req.clone({
          params: encodedParams
        })
      );
    }

    return next.handle(req);
  }
}
