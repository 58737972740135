import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { CustomRouterState } from './custom-route.model';

export class CustomSerializer implements RouterStateSerializer<CustomRouterState> {
  public serialize(routerState: RouterStateSnapshot): CustomRouterState {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;

    const { params, data } = route;

    // Only return an object including the URL, params, data and query params
    // instead of the entire snapshot
    return { url, params, data, queryParams };
  }
}
