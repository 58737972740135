import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/modules/material.module';
import { ConsentBannerComponent } from './consent-banner.component';

@NgModule({
  declarations: [ConsentBannerComponent],
  imports: [CommonModule, MaterialModule, RouterModule, TranslateModule],
  exports: [ConsentBannerComponent]
})
export class ConsentBannerModule {}
