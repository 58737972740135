import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DayjsService } from './dayjs.service';

// Dayjs plugins
dayjs.extend(localizedFormat);

@NgModule({
  imports: [TranslateModule],
  providers: [DayjsService]
})
export class DayjsModule {
  // NOTE: See https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
  constructor(private service: DayjsService, @Optional() @SkipSelf() parentModule: DayjsModule) {
    if (parentModule) {
      throw new Error(
        `${parentModule.constructor.name} has already been loaded. Import this module in the AppModule only.`
      );
    }

    this.service.init();
  }
}
