import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_LANG } from '@configs/app.config';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/apis')) {
      return next.handle(
        req.clone({
          setHeaders: {
            'Accept-Language':
              this.translateService.currentLang || this.translateService.defaultLang || DEFAULT_LANG
          }
        })
      );
    }

    return next.handle(req);
  }
}
