import { HttpUrlEncodingCodec } from '@angular/common/http';

export class EncodeParamCodec extends HttpUrlEncodingCodec {
  public encodeKey(key: string): string {
    return super.encodeKey(key);
  }

  public encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  public decodeKey(key: string): string {
    return super.decodeKey(key);
  }

  public decodeValue(value: string): string {
    return super.decodeValue(value);
  }
}
